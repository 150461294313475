import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { getInitials } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import GenericLinkModal from '../GenericLinkModal/GenericLinkModal';
import useAlert from '../../../hooks/useAlert';
import { KadaAvatar, KadaBadge, KadaBtn, KadaDropdownBtn, KadaIconBtn, KadaLoadingBar, KadaTableHeader, kButtonVariants, kDropdownBtnVariants } from 'kada-component-library'

const Issues = props => {

  const {
    history,
    dispatch,
    state
  } = props;

  const [dataLoading, setDataLoading] = useState(false)
  const [linkModalOpen, setLinkModalOpen] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const enrichData = async () => {
    let newData = {
      ...state.issueData
    }
    setDataLoading(true)
    for(let i=0; i<newData.items.length; i++){
      if(newData.creatorLoaded)continue;
      let item = newData.items[i]
      await axiosCerebrum
          .get(`/api/issues/${item.id}/related`,{params:{relationship:'CREATED_BY',per_page:1}})
          // eslint-disable-next-line
          .then(response=>{
            newData = {
              ...newData,
              items:newData.items.map(i=>{
                if(i.id!==item.id)return i;
                return {
                  ...i,
                  creatorLoaded:true,
                  creator:response.data.items[0]
                }
              })
            }
          })
          // eslint-disable-next-line
          .catch(error=>{
            newData = {
              ...newData,
              items:newData.items.map(i=>{
                if(i.id!==item.id)return i;
                return {
                  ...i,
                  creatorLoaded:true,
                }
              })
            }
            console.log(error)
          })
      }

      dispatch({
        type:'set_issue_data',
        issueData:newData
      })

      setDataLoading(false)
    }

  useEffect(()=>{
    if(state.issueData && state.issueData.items.some(i=>!i.creatorLoaded) && !dataLoading){
      enrichData()
    }
  // eslint-disable-next-line
  },[state.issueData])

  if (state.issueLoading ) return (
    <KadaLoadingBar
      text='Loading'
    />
  );

  if (state.issueError) return <p>Fail to load issues</p>;

  if(!state.issueData)return <div></div>

  let initialState = 'ALL';
  let closedIssueCount = state.issueData.items.filter(el=>el.issue_status_txt==='CLOSED').length;
  let openIssueCount = state.issueData.items.filter(el=>el.issue_status_txt==='OPEN').length;
  let inProgressIssueCount = state.issueData.items.filter(el=>el.issue_status_txt==='IN_PROGRESS').length;

  const tabState = state.issueData.tabState || initialState;
  const sort = state.issueData.sort || 'Title,1';

  const setTabState = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,tabState:value,search:''}
    })
  }

  const onSortChange = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,sort:value}
    })
  }

  // const onSearchChange = value => {
  //   dispatch({
  //     type:'set_issue_data',
  //     issueData:{...state.issueData,search:value}
  //   })
  // }

  const onRemoveIssue = (el) => {
    axiosCerebrum
      .delete(
        `/api/issues/${el.id}/related?relationship=IMPACTS&object_id=${state.basicData.id}`
      )
      .then(response=>{
        dispatch({
          type:'set_issue_data',
          issueData:{
            ...state.issueData,
            items:state.issueData.items.filter(i=>i.id!==el.id)
          }
        })
        sendAlert({
          message: `Link to ${el.name_txt} removed`,
          type: 'info'
        })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({
          message: `Error occurred unlinking ${el.name_txt}`,
          type: 'error'
        })
      })
  }

  const getIssueId = fullName => {
    return fullName.split(':')[0]
  }

  const getIssueName = fullName => {
    return fullName.split(':').slice(1).join(':')
  }

  const processList = list => {
    if(tabState==='OPEN'){
      list = list.filter(l=>l.issue_status_txt==='OPEN');
    }
    else if(tabState==='IN_PROGRESS'){
      list = list.filter(l=>l.issue_status_txt==='IN_PROGRESS');
    }
    else if(tabState==='CLOSED'){
      list = list.filter(l=>l.issue_status_txt==='CLOSED')
    }

    list = list.filter(l=> !state.issueData.search || (l.name_txt||l.name_srt||'').toLowerCase().includes(state.issueData.search.toLowerCase()))

    if(sort==='ID,1'){
      list.sort((a,b)=>getIssueId(a.name_txt).localeCompare(getIssueId(b.name_txt)))
    }
    if(sort==='ID,2'){
      list.sort((a,b)=>getIssueId(b.name_txt).localeCompare(getIssueId(a.name_txt)))
    }
    if(sort==='Title,1'){
      list.sort((a,b)=>getIssueName(a.name_txt).localeCompare(getIssueName(b.name_txt)))
    }
    if(sort==='Title,2'){
      list.sort((a,b)=>getIssueName(b.name_txt).localeCompare(getIssueName(a.name_txt)))
    }
    if(sort==='Priority,1'){
      let orderMap = {LOW:1, MEDIUM:2, HIGH:3}
      list.sort((a,b)=>orderMap[a.issue_severity] - orderMap[b.issue_severity])
    }
    if(sort==='Priority,2'){
      let orderMap = {LOW:3, MEDIUM:2, HIGH:1}
      list.sort((a,b)=>orderMap[a.issue_severity] - orderMap[b.issue_severity])
    }
    return list;
  }

  const tabOptions = ['ALL', ...['OPEN','IN_PROGRESS','CLOSED'].filter(el=>state.issueData.items.find(i=>i.issue_status_txt===el))]

  return (
    <div>
      <div
        className='flex items-center gap-4 mb-2'
      >
        <p>ISSUES: </p>
        {
          [
            {text:`All (${state.issueData.items.length})`,value:'ALL'},
            {text:`Open (${openIssueCount})`,value:'OPEN'},
            {text:`In progress (${inProgressIssueCount})`,value:'IN_PROGRESS'},
            {text:`Closed (${closedIssueCount})`,value:'CLOSED'},
          ]
          .filter(el=>tabOptions.includes(el.value))
          .map(el => (
            <KadaBtn
              text={el.text}
              size="s"
              onClick={()=>setTabState(el.value)}
              variant={ tabState === el.value ? kButtonVariants.primarySelected : kButtonVariants.primaryOutlined }
            />
          ))
        }
        <div class="flex-grow"></div>
        <KadaIconBtn
          iconName='add'
          onClick={() => setLinkModalOpen(true)}
        />
        {/* <KadaDropdownBtn
          iconName='more_vert'
          iconOnly
          variant={kDropdownBtnVariants.roundedIconButton}
          options={[
            {text:'Link issue', onClick:()=>{}},
          ]}
        /> */}
      </div>
      <div>
        {
          processList(state.issueData.items).length===0
          ?
          (
            <p className="mt-4">
              No issues found
            </p>
          )
          :
          <table className="table table-wrap" id="issue-table">
            <KadaTableHeader
              tableId='issue-table'
              options={[
                {name:'ID',sortable:true},
                {name:'Title',sortable:true},
                {name:'Status',sortable:false},
                {name:'Priority',sortable:true},
                {name:'Assignee',sortable:false},
                {name:'',sortable:false}
              ]}
              onChange={e=>{
                onSortChange(e.detail.value)
              }}
              value={sort}
            />
            {
              processList(state.issueData.items).map((el, index) => (
                <tr>
                  <td class="w-25">
                    <a
                      className={'underline cursor-pointer'}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/profile/issue/${el.id}`)
                      }}
                      href={`/profile/issue/${el.id}`}
                    >
                      {el.name_txt.split(':')[0]}
                    </a>
                  </td>
                  <td>{el.name_txt.split(':').slice(1).join(':')}</td>
                  <td>
                    <KadaBadge
                      text={el.issue_status_txt}
                    />
                  </td>
                  <td>
                    <KadaBadge
                      text={el.issue_severity}
                    />
                  </td>
                  <td class="w-22">
                    {
                      el.creator ?
                      <KadaAvatar
                        text={getInitials(el.creator.name)}
                        className={'cursor-pointer'}
                        tooltip={el.creator.name}
                        onClick={() => history.push(`/profile/user/${el.creator.id}`)}
                      />
                      : 'N/A'
                    }
                  </td>
                  <td class="w-6">
                    <KadaDropdownBtn
                      iconName='more_horiz'
                      iconOnly
                      variant={kDropdownBtnVariants.roundedIconButton}
                      options={[
                        {text:'Remove issue', onClick:()=>onRemoveIssue(el)},
                      ]}
                    />
                  </td>
                </tr>
              ))
            }
          </table>
        }
      </div>
      <GenericLinkModal
        object={state.basicData}
        history={history}
        modalOpen={linkModalOpen}
        setModalOpen={setLinkModalOpen}
        linkableObjects={['ISSUE']}
        relations={['IMPACTED_BY']}
        profileDispatch={dispatch}
        allowAddNewIssue={true}
        onLinkUpdated={()=>{
          window.postMessage({reload_issue:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
        }}
      />
    </div>
  )
}

Issues.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default Issues;
